import React, { useState, useEffect } from 'react';
import '../../assets/styles/streams.css';
import { useSocket } from '../../websocket/websocket';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDarkMode } from '../../context/DarkModeContext';

export default function StreamUrls(){

    const { streamUrls, prioWords, socket, eventList } = useSocket();
    const [streams, setStreams] = useState([]);
    const [events, setEvents] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedSession, setSelectedSession] = useState(null);
    const [editingStreamId, setEditingStreamId] = useState(null);
    const [newUrl, setNewUrl] = useState('');
    const [wordList, setWordList] = useState([]);
    const [newWord, setNewWord] = useState('');
    const [streamStatuses, setStreamStatuses] = useState(new Set());    
    const { isDarkMode, toggleDarkMode } = useDarkMode();
    

    // connect socket listeners on render
    useEffect(() => {
        // repsonse when stream url updates
        socket.on('on_streamurls_updated_response', (message) => {
            if(message.success){
                toast.success('Stream URL updated successfully!');                
                setEditingStreamId(null);
                setNewUrl('');
            }else{
                toast.error('Failed to update Stream URL.');
            }
        })

        // response when prio words updates
        socket.on('on_priowords_updated', (message) => {
            console.log(message)
            const word = message.word
            if(message.operation === 'DELETE'){
                setWordList((prevWordList) => prevWordList.filter((w) => w !== word));
                toast.success('deleted', {word}, 'from list')
            }
        })

        socket.on('on_get_stream_update', (message) => {
            console.log(message)
        })

        // get active streams
        socket.on('on_get_streams_streams', (message) => {
            console.log(message)
            const newStreamStatuses = new Set(streamStatuses);

            Object.keys(message).forEach((streamName) => {
                newStreamStatuses.add(streamName);
            });

            setStreamStatuses(newStreamStatuses);
        })
    }, [])

    // set prio word list from context
    useEffect(() => {
        if (prioWords && prioWords.data && prioWords.data.prioritywordlist) {
          setWordList(prioWords.data.prioritywordlist);
          console.log('Updated prioWords:', prioWords.data.prioritywordlist);
        }
    }, [prioWords]);

    // set events from context
    useEffect(() => {
        if(eventList){
            setEvents(eventList)
        }
    }, [eventList])

    // get active streams for this session
    useEffect(() => {
        if(selectedSession){
            socket.emit('_driverRadio', {
                event: 'on_get_streams_streams',
                operation: 'getStreams',
                params: {
                    session_id: selectedSession
                }
            })

            socket.emit('_driverRadioStreams', {
                operation: 'startStreamSubs',
                params: {
                    sessions_id: selectedSession
                }
            })
        }
    }, [selectedSession])

    // get session ids if event is selected
    useEffect(() => {
        if (selectedEvent){
            console.log(selectedEvent)
        }
        
        // request session ids for event
        socket.emit('MR', {
            operation: 'MR_SUBSCRIBE_EVENT',
            params: {eventId: selectedEvent}
        })

        // get session ids for event
        socket.on('MR_EVENT_UPDATED', (message) => {
            const sessions = Object.keys(message.newIndex.sessions).map((sessionId) => {
                const session = message.newIndex.sessions[sessionId];
                return {
                  sessionId: session.docID,
                  sessionName: session.name
                };
              });
            setSessions(sessions)
        })
    }, [selectedEvent, socket]);

    // set streams from context
    useEffect(() => {
        const status = {}
        if (streamUrls && streamUrls.data) {
            const streams = Object.entries(streamUrls.data).map(([key, url], index) => ({
                id: index + 1,
                name: key,
                url: url,
            }));
        setStreams(streams);
        }
    }, [streamUrls]);
    
    // update stream url
    const handleUpdateUrl = (id, name, url) => {
        setStreams((prevStreams) => 
            prevStreams.map((stream) =>
                stream.id === id ? { ...stream, url: newUrl } : stream
            )
        );

        socket.emit('_driverRadioStreams', {
            event: 'on_streamurls_updated_response',
            operation: 'updateStreamurls',
            params: {
                [name]: url
            }
        })
        
    };

    // enable editing mode
    const handleEditStream = (id, currentUrl) => {
        setEditingStreamId(id);
        setNewUrl(currentUrl);
    };

    // start stream, opens connection to azure for transcription
    const handleStartStream = (id, url) => {
        const sessionId = selectedSession
        const eventId = selectedEvent
        socket.emit('_driverRadio', {
            event: "",
            operation: 'controlStream',
            params:{
                command: 'START',
                driver_id: id,
                event_id: eventId,
                session_id: sessionId,
                url: url
            }
        })
        setStreamStatuses(prevStatuses => {
            const newStatuses = new Set(prevStatuses);
            newStatuses.add(id);
            return newStatuses;
        });

        socket.on('on_get_stream_update', (message) => {
            console.log(message)
        })
    };

    // stop stream/connection to azure
    const handleStopStream = (id, url) => {
        const sessionId = selectedSession
        const eventId = selectedEvent
        socket.emit('_driverRadio', {
            event: '',
            operation: 'controlStream',
            params:{
                command: 'STOP',
                driver_id: id,
                event_id: eventId,
                session_id: sessionId,
                url: url
            }
        })
        setStreamStatuses(prevStatuses => {
            const newStatuses = new Set(prevStatuses);
            newStatuses.delete(id);
            return newStatuses;
        });

        socket.on('on_get_stream_update', (message) => {
            console.log(message)
        })
    };
    
    // add a word to list
    const handleAddWord = () => {
        if (newWord.trim() !== '') {
            socket.emit('_driverRadio', {
                event: 'on_priowords_updated_response',
                operation: 'updatePriowords',
                params: {
                    operation: 'ADD',
                    word: newWord
                }
            })

            socket.on('on_priowords_updated_response', (message) => {
                if(message.success === true){
                    setWordList([...wordList, newWord.trim()]);
                    
                    setNewWord('');
                }      
            })
        }
        toast.success('added new word to list')
        return () => {
            socket.off('on_priowords_updated_response')
        }
    };

    // delet word from list
    const handleDeleteWord = (word) => {

        socket.emit('_driverRadio', {
            event: 'on_priowords_updated_response',
            operation: 'updatePriowords',
            params: {
                operation: 'DELETE',
                word: word
            }
        })

        socket.on('on_priowords_updated_response', (message) => {
            if(message.success === true){
                setWordList((prevWordList) => prevWordList.filter((w) => w !== word));
            }
        })
        toast.success('deleted word from list')
        return () => {
            socket.off('on_priowords_updated_response')
        }
    };

    const isActionAllowed = selectedEvent && selectedSession;

    return(
        <div className="content">
            <ToastContainer/>
            <div className='stream-container'>
                <div className='selectors'>
                    <select
                        id="event"
                        value={selectedEvent}
                        onChange={(e) => setSelectedEvent(e.target.value)}
                    >
                        <option value="">Event</option>
                        {events.map((event) => (
                        <option key={event.docID} value={event.docID}>
                            {event.name}
                        </option>
                        ))}
                    </select>
                    <select
                        id="session"
                        value={selectedSession}
                        onChange={(e) => setSelectedSession(e.target.value)}
                    >
                        <option value="">Session</option>
                        {sessions.map((session) => (
                            <option key={session.sessionId} value={session.sessionId}>
                                {session.sessionName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="stream-table">
                    <h2>Streams</h2>
                    <div className='scrollable-table-container'>
                        <table className='styled-table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>URL</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {streams.map((stream) => (
                                    <tr key={stream.id}>
                                        <td>{stream.name}</td>
                                        <td>
                                            {editingStreamId === stream.id ? (
                                                <input 
                                                    type="text" 
                                                    value={newUrl}
                                                    onChange={(e) => setNewUrl(e.target.value)}
                                                />
                                            ) : (
                                                stream.url
                                            )}
                                        </td>
                                        <td>
                                            {editingStreamId === stream.id ? (
                                                <button onClick={() => handleUpdateUrl(stream.id, stream.name, newUrl)}>
                                                    Save
                                                </button>
                                            ) : (
                                                <button onClick={() => handleEditStream(stream.id, stream.url)}>
                                                    Edit
                                                </button>
                                            )}
                                            {isActionAllowed && (
                                                streamStatuses.has(stream.name) ? (
                                                    <button onClick={() => handleStopStream(stream.name, stream.url)}>set dead</button>
                                                ):(
                                                    <button onClick={() => handleStartStream(stream.name, stream.url)}>set live</button>
                                                )
                                            )}
                                            
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <h2>Word List</h2>
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th>
                                <input
                                        type="text"
                                        value={newWord}
                                        onChange={(e) => setNewWord(e.target.value)}
                                        placeholder="Add a new word"
                                    /> 
                                </th>
                                <th><button onClick={handleAddWord}>Add</button></th>
                            </tr>
                        </thead>
                        <tbody>
                            {wordList.map((word, index) => (
                                <tr key={index}>
                                    <td>{word}</td>
                                    <td>
                                        <button onClick={() => handleDeleteWord(word)} className="delete-button">
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>            
        </div>
    )
}