import React, { useEffect, useRef } from 'react';
import '../assets/styles/modal.css'; 
import { useDarkMode } from '../context/DarkModeContext';


const Modal = ({ isOpen, onClose, children }) => {
    const modalRef = useRef();
    const { isDarkMode, toggleDarkMode } = useDarkMode();

    // Close the modal when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    // Trap focus within the modal
    useEffect(() => {
        const handleTabKey = (e) => {
            if (e.keyCode === 9) { // Tab key
                const focusableModalElements = modalRef.current.querySelectorAll('a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])');
                const firstElement = focusableModalElements[0];
                const lastElement = focusableModalElements[focusableModalElements.length - 1];

                if (!e.shiftKey && document.activeElement === lastElement) {
                    firstElement.focus();
                    e.preventDefault();
                }

                if (e.shiftKey && document.activeElement === firstElement) {
                    lastElement.focus();
                    e.preventDefault();
                }
            }
        };

        if (isOpen) {
            document.addEventListener('keydown', handleTabKey);
        } else {
            document.removeEventListener('keydown', handleTabKey);
        }

        return () => {
            document.removeEventListener('keydown', handleTabKey);
        };
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className={`modal-content ${isDarkMode ? 'bg-dark text-white' : 'text-dark'}`} ref={modalRef}>
                <button className={`modal-close ${isDarkMode ? 'text-white' : 'text-dark'}`} onClick={onClose}>×</button>
                {children}
            </div>
        </div>
    );
};

export default Modal;