import React, { useCallback, useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './assets/styles/App.css';
import Audio from './pages/audio';
import Empty from './pages/empty';
import Streams from './pages/admin-pages/streams';
import PlanStream from './pages/admin-pages/plan-stream';
import FlyoutMenu from './components/flyout';
import {
    PWordmark,
    PButtonPure,
} from '@porsche-design-system/components-react';

import { SocketProvider } from './websocket/websocket';
import { EventProvider } from './websocket/eventContext';
import { DarkModeProvider, useDarkMode } from './context/DarkModeContext';

export default function App() {
    const [isFlyoutNavigationOpen, setIsFlyoutNavigationOpen] = useState(false);
    const [flyoutNavigationActiveIdentifier, setFlyoutNavigationActiveIdentifier] = useState('audio');

    const onOpen = useCallback(() => {
        setIsFlyoutNavigationOpen(true);
    }, []);
    const onDismiss = useCallback(() => {
        setIsFlyoutNavigationOpen(false);
    }, []);
    const onUpdate = useCallback(
        (e) =>
            setFlyoutNavigationActiveIdentifier(e.detail.activeIdentifier),
        []
    );

    return (
        <EventProvider>
            <SocketProvider>
                <DarkModeProvider>
                    <AppContent
                        isFlyoutNavigationOpen={isFlyoutNavigationOpen}
                        onOpen={onOpen}
                        onDismiss={onDismiss}
                        onUpdate={onUpdate}
                        flyoutNavigationActiveIdentifier={flyoutNavigationActiveIdentifier}
                    />
                </DarkModeProvider>
            </SocketProvider>
        </EventProvider>
    );
}

function AppContent({ isFlyoutNavigationOpen, onOpen, onDismiss, onUpdate, flyoutNavigationActiveIdentifier }) {
    const { isDarkMode, toggleDarkMode } = useDarkMode();

    useEffect(() => {
        document.body.classList.toggle('dark-mode', isDarkMode);
    }, [isDarkMode]);

    return (
        <div className={isDarkMode ? 'dark-mode' : 'light-mode'}>
            <div className="grid-container">
                <PWordmark className='wordmark' theme='dark' />

                <PButtonPure className="menu"
                    icon='menu-lines'
                    theme="dark"
                    type="button"
                    aria={{ 'aria-haspopup': 'dialog' }}
                    onClick={onOpen}>
                    Menu
                </PButtonPure>

                <PButtonPure className='darkmode'
                    icon={isDarkMode ? 'sun' : 'moon'}
                    theme='dark'
                    type="button"
                    onClick={toggleDarkMode}>
                </PButtonPure>

                <FlyoutMenu
                    isFlyoutNavigationOpen={isFlyoutNavigationOpen}
                    onDismiss={onDismiss}
                    onUpdate={onUpdate}
                    flyoutNavigationActiveIdentifier={flyoutNavigationActiveIdentifier}
                />

                <Routes>
                    <Route path="/" element={<Empty />} />
                    <Route path="/audio" element={<Audio />} />
                    <Route path='/streams' element={<Streams />} />
                    <Route path='/plan-stream' element={<PlanStream />} />
                </Routes>
            </div>
        </div>
    );
}