import React, { useState, useRef, memo, useEffect, useCallback } from "react";
import '../assets/styles/card.css'
import Hls from "hls.js";
import DetailModal from "./detail_modal";
import {
    PButtonPure,
    PText
} from '@porsche-design-system/components-react';
import { useDarkMode } from '../context/DarkModeContext';

const Card = memo(({ title, url, eventId, sessionId, driverId, isLive, content }) => {
    const cardRef = useRef(null);
    const contentArray = Array.isArray(content) ? content : ["no data"];
    const hasNoData = contentArray.includes('no data');
    const audioRef = useRef(null);
    const hlsRef = useRef(null);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(true);
    const { isDarkMode, toggleDarkMode } = useDarkMode();
    
    const handleScroll = () => {
        if (cardRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = cardRef.current;
            setIsAtBottom(scrollHeight - scrollTop === clientHeight);
        }
    };

    useEffect(() => {
        const cardElement = cardRef.current;
        if (cardElement) {
            cardElement.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (cardElement) {
                cardElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        if (cardRef.current && isAtBottom) {
            cardRef.current.scrollTop = cardRef.current.scrollHeight;
        }
    }, [content, isAtBottom]);

    // unix to hour - minute - second
    const formatTimestamp = useCallback((unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return date.toLocaleString(undefined, options);
    }, []);

    // unix to year - month - day
    const formatDate = useCallback((unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    }, []);

    const handleLabelClick = (item) => {
        setSelectedLabel(item);
    };

    const handleCloseModal = () => {
        setSelectedLabel(null);
    };

    // renders a label with the time and text and renders a modal onClick
    const renderContent = useCallback(
        (obj) => (
            <ul className="text-list">
                {obj.map((item, i) => (
                    <li key={i}
                        className={item.priority === 1 ? 'prio-high' : 'prio-low'}>
                        <label className="text-row"
                            onClick={() => handleLabelClick(item)}
                        >
                            <span className="timestamp">{formatTimestamp(item.time)}:</span>
                            <span className="transcribed_text">{item.text}</span>
                        </label>
                    </li>
                ))}
            </ul>
        ),
        [handleLabelClick, formatTimestamp]
    );

    // load hls stream when card is visible
    useEffect(() => {
        const audio = audioRef.current;
        if (audio && Hls.isSupported() && url.endsWith('.m3u8')) {
            const hls = new Hls();
            hlsRef.current = hls;
            hls.loadSource(url);
            hls.attachMedia(audio);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                console.log('HLS manifest loaded');
            });

            return () => {
                hls.destroy();
            };
        }
    }, [url]);

    // play audio stream when isPLaying is true
    useEffect(() => {
        if (isPlaying) {
            const audio = audioRef.current;
            var playPromise = audio.play();

            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    audio.pause();
                })
                    .catch(error => {
                        console.log(error)
                    });
            }
            console.log('playing stream', driverId)
        }
    }, [isPlaying, driverId])

    // play stream button handler
    const playStream = useCallback(() => {
        setIsPlaying(true)
        console.log('play stream')
    }, []);

    // stop stream button handler
    const stopStream = useCallback(() => {
        setIsPlaying(false)
        console.log('stop stream')
    }, []);

    // Get the date from the first content item
    const date = contentArray.length > 0 && contentArray[0].time ? formatDate(contentArray[0].time) : "";
    
    return (
        <div className={`card 
            ${isDarkMode ? 'text-white bg-dark' : ''}
            ${isPlaying ? 'border-danger' : ''}`}>
            <div className={`card-header ${isDarkMode ? 'border-light' : ''}`}>
                <div>
                    <h5 size="medium">
                        {title} - <span style={{fontWeight:400}}>{date}</span>
                    </h5>
                    <label className={isLive ? 'live' : 'not-live'}> live</label>
                </div>
            </div>

            <div className="card-body" ref={cardRef}>
                {hasNoData ? (
                    <PText>no data</PText>
                ) : (
                    renderContent(contentArray)
                )}
                {selectedLabel && (
                    <DetailModal
                        isOpen={!!selectedLabel}
                        onClose={handleCloseModal}
                        name={title}
                        time={formatTimestamp(selectedLabel.time)}
                        text={selectedLabel.text}
                        eventId={eventId}
                        sessionId={sessionId}
                        driverId={driverId}
                        timestamp={selectedLabel.timestamp}
                        priority={selectedLabel.priority}
                    />
                )}
            </div>
            <div className={`card-footer ${isDarkMode ? 'border-light' : ''}`}>
                {isPlaying ? (
                    <PButtonPure icon='pause' onClick={stopStream} 
                    theme={`${isDarkMode ? 'dark' : 'light'}`}/>
                ) : (
                    <PButtonPure icon='play' onClick={playStream} 
                    theme={`${isDarkMode ? 'dark' : 'light'}`}/>
                )}
            </div>
            <audio ref={audioRef} src={url} preload="none" />
        </div>
    )
});

export default Card;